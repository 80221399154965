import { NAMESPACES } from "../../namespaces";
import { dictionaryHR } from "./dictionary";
import clinicsHR from "./clinics-hr.json";
import nightClubHR from "./night-club-hr.json";
import restaurantHR from "./restaurant-hr.json";
import coWorkingSpaceHR from "./co-working-space-hr.json";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [NAMESPACES.DICTIONARY]: dictionaryHR,
  [NAMESPACES.RESTAURANT]: { ...dictionaryHR, ...restaurantHR },
  [NAMESPACES.CLINICS]: { ...dictionaryHR, ...clinicsHR },
  [NAMESPACES.NIGHT_CLUB]: { ...dictionaryHR, ...nightClubHR },
  [NAMESPACES.COWORKING_SPACE]: { ...dictionaryHR, ...coWorkingSpaceHR },
};
