import React from "react";
import { createContext } from "react";
import { useReservationNavigation } from "../hooks/useReservationNavigation";

export const ReservationNavigationContext = createContext({});

export const ReservationNavigationProvider = ({ children }) => {
  const handlers = useReservationNavigation();

  return (
    <ReservationNavigationContext.Provider value={handlers}>
      {children}
    </ReservationNavigationContext.Provider>
  );
};
