import { Outlet } from "react-router-dom";
import { ReservationEntryProvider } from "../context/reservationEntryContext";
import { ReservationDataProvider } from "../context/reservationDataContext";
import { ReservationNavigationProvider } from "../context/reservationNavigationContext";

const ReservationProviders = () => {
  return (
    <ReservationEntryProvider>
      <ReservationDataProvider>
        <ReservationNavigationProvider>
          <Outlet />
        </ReservationNavigationProvider>
      </ReservationDataProvider>
    </ReservationEntryProvider>
  );
};

export default ReservationProviders;