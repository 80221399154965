import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReservationInformation from "./ReservationInformation";

export default function ExpiredReservationDetails({reservation, start, end}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {reservation != null ? 
        <div className="flex-center">
          <div className="details-container">

            <ReservationInformation 
                start={start} 
                startTime={reservation.startTime} 
                endTime={reservation.endTime}
                numberOfPersons={reservation.numberOfPersons} />

            <p className="standard-p">
              {t("ReservationDetails.ThankYouForWelcoming", {locationName: reservation.locationName})}
            </p>
            <button onClick={() => navigate(`/${reservation.company}/${reservation.location}`)} className="card-button" style={{ width: "60%", marginTop: '1em' }}>
              {t("ReservationDetails.MakeNewReservation")}
            </button>
          </div>
        </div> : null 
    } </>
  );
}