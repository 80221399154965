import { useContext } from "react";
import { ReservationDataContext } from "../context/reservationDataContext";
import { useTranslation } from "react-i18next";
import { FINAL_STEP } from "../constants/reservationSteps";

export default function Final() {
  const { t } = useTranslation();
  const { steps, locationName } = useContext(ReservationDataContext);

  const notes = steps.find(x => x.id === FINAL_STEP).notes;

  return (
    <div className="final-container">
      {
        notes.length > 0 ? 
          notes.map((note) =>
            <p style={{maxWidth: '25rem', textAlign: 'center', color: '#314D69', margin: '0.8em 0'}}>
              {note}
            </p>
          )
        : 
        <>
          <h3>{t("FinalStep.Title")}</h3>
          <p>{t("FinalStep.TextOne", { locationName })}</p>
          <p>{t("FinalStep.TextTwo")}</p>
        </>
      }
    </div>
  );
}
