import CalendarDay from "../components/CalendarDay";
import OptionPicker from "../components/OptionPicker";
import { useEffect, useContext, useState, useRef } from "react";
import { PostRequest } from "../api/requests/postRequest";
import { ReservationEntryContext } from "../context/reservationEntryContext";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import { formatToDayMonthYear } from "../helpers/useDateHelpers";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ReservationDataContext } from "../context/reservationDataContext";
import { TIME_STEP } from "../constants/reservationSteps";

export default function Time() {
  const { t, i18n } = useTranslation();
  const { maxDaysInAdvance, steps, locationName, companySlug, hasCompanyOtherLocations } = useContext(
    ReservationDataContext
  );
  
  const notes = steps.find(x => x.id === TIME_STEP).notes;
  const navigate = useNavigate();

  const currentDate = new Date();
  const maxDate = new Date();
  maxDate.setDate(currentDate.getDate() + +maxDaysInAdvance);

  currentDate.setHours(0, 0, 0, 0);
  const { nextStep } = useContext(ReservationNavigationContext);
  const { reservation, onReservationChange } = useContext(
    ReservationEntryContext
  );
  const [timeSlots, setTimeSlots] = useState('');
  const [weekTimeSlots, setWeekTimeSlots] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const selectedDate = useRef(reservation.startDate);
  const [targetDayInMonth, setTargetDayInMonth] = useState(0);
  const [targetMonthName, setTargetMonthName] = useState("");
  const [targetYear, setTargetYear] = useState();

  const handleTimeSlotChange = (e) => {
    onReservationChange({ startTime: e.target.value });
    nextStep();
  };

  const handleDateChange = (e) => {
    const date = new Date(e.target.value);
    const dayInMonth = date.getDate();
    setTargetDayInMonth(dayInMonth);
    setTargetMonthName(date.toLocaleString(i18n.language, { month: "long" }));
    setTargetYear(date.getFullYear());
    selectedDate.current = date;
    setTimeSlots(
      weekTimeSlots.find((x) => x.dayInMonth === dayInMonth).timeslots
    );
  };

  
  useEffect(() => {
    setTargetMonthName(() => selectedDate.current.toLocaleString(i18n.language, { month: "long" }));
  }, [i18n.language])

  useEffect(() => {
    const resDate = new Date(reservation.startDate);
    const dayInMonth = resDate.getDate();
    setTargetDayInMonth(dayInMonth);
    setTargetMonthName(resDate.toLocaleString(i18n.language, { month: "long" }));
    setTargetYear(resDate.getFullYear());

    const LoadTimeSlots = async () => {
      if (!reservation.locationId) return;

      try {
        await PostRequest(
          `/api/v1/locations/${reservation.locationId}/time-slots`,
          reservation
        ).then((response) => {
          if (response) {
            setWeekTimeSlots(response);
            setTimeSlots(
              response.find((x) => x.dayInMonth === dayInMonth).timeslots
            );
          }
        });
      } catch {
        // NAVIGIRAJ NEGDI
      }
    };

    var day = resDate.getDay(),
      diff = resDate.getDate() - day + (day === 0 ? -7 : 0);
    var firstday = new Date(resDate.setDate(diff));

    var dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(new Date(firstday.setDate(firstday.getDate() + 1)));
    }

    setDaysOfWeek(dates);
    LoadTimeSlots();
  }, [reservation, i18n.language]);

  useEffect(
    () => () => {
      onReservationChange({ startDate: selectedDate.current });
    },
    [onReservationChange]
  );

  return (
    <div className="flex-direction-column">
      <h1 className="large-p">{t('TimeStep.Title')}</h1>

      <div className="page">
        <div className="wrapper">
          <div className="react-calendar">
            <div className="react-calendar__navigation">
              <button
                aria-label=""
                className="react-calendar__navigation__label flex-grow-1"
                type="button"
              >
                <span className="react-calendar__navigation__label__labelText react-calendar__navigation__label__labelText--from">
                  {targetMonthName} {targetYear}
                </span>
              </button>
            </div>
            <div className="react-calendar__viewContainer">
              <div className="react-calendar__month-view">
                <div className="flex-align-left">
                  <div className="flex-glow-width-100">
                    <div className="react-calendar__month-view__weekdays flex-no-wrap">
                      <CalendarDay name={t("Calendar.Days.Monday")} shortName={t("Calendar.DaysShort.Monday")} />
                      <CalendarDay name={t("Calendar.Days.Tuesday")} shortName={t("Calendar.DaysShort.Tuesday")} />
                      <CalendarDay name={t("Calendar.Days.Wednesday")} shortName={t("Calendar.DaysShort.Wednesday")} />
                      <CalendarDay name={t("Calendar.Days.Thursday")} shortName={t("Calendar.DaysShort.Thursday")} />
                      <CalendarDay name={t("Calendar.Days.Friday")} shortName={t("Calendar.DaysShort.Friday")} />
                      <CalendarDay name={t("Calendar.Days.Saturday")} shortName={t("Calendar.DaysShort.Saturday")} />
                      <CalendarDay name={t("Calendar.Days.Sunday")} shortName={t("Calendar.DaysShort.Sunday")} />
                    </div>

                    <div className="react-calendar__month-view__days flex-wrap">
                      {daysOfWeek.map((day) => {
                        return (
                          <button
                            key={day.getDate()}
                            style={
                              day.getDate() === targetDayInMonth
                                ? {
                                    backgroundColor: "#314D69",
                                    color: 'white',
                                    borderRadius: '10px'
                                  }
                                : {}
                            }
                            className="react-calendar__tile react-calendar__month-view__days__day react-calendar-flex"
                            type="button"
                            disabled={currentDate > day || day > maxDate ? "true" : ""}
                            value={day}
                            onClick={handleDateChange}
                          >
                            {day.getDate()}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          {timeSlots.length !== 0 ? (
            <>
              {timeSlots.map((timeslot) => {
                  return (
                    <>
                      <OptionPicker
                        key={timeslot.start}
                        name="timeslot"
                        identity={timeslot.start}
                        text={ timeslot.end === null  ? 
                          timeslot.start.substring(0, 5) :
                          timeslot.start.substring(0, 5) + " - " + timeslot.end.substring(0, 5)
                        }
                        handleChange={handleTimeSlotChange}
                      />
    
                      
                    </>
                  );
                })
              }

              {notes.length > 0 ? 
                notes.map((note) =>
                  <p style={{marginTop: '2em', width: '140%', textAlign: 'center', color: '#314D69', fontWeight: 'bold'}}>
                    {note}
                  </p>
                )
              : null }
            </>
          ) : (
            <p style={{textAlign: 'center', fontSize: '0.9em', marginBottom: '1em'}}>
              {timeSlots === '' ? 'Loading...' : null}

              {timeSlots !== '' ? t("TimeStep.NoTimes", {
                date: formatToDayMonthYear(selectedDate.current, i18n.language),
                location: locationName
              }) : null}

              {timeSlots !== '' && hasCompanyOtherLocations ?  
                <Link style={{cursor: 'pointer', color: '#314D69'}} to={`/${companySlug}`}>
                  {t("TimeStep.DontWorrySeeOtherLocations")}
                </Link> 
              : null}
            </p>
          )}

          {hasCompanyOtherLocations && timeSlots.length !== 0 ? 
            <button onClick={() => navigate(`/${companySlug}`)} style={{color: '#314D69', cursor: 'pointer', border: 0, background: 'transparent', outline: 'none', marginTop: '1em', fontSize: '0.9em'}}>
              {t("TimeStep.OtherLocations")}
            </button>
          : null}
        </div>
      </div>
    </div>
  );
}
