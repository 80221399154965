import { dictionaryEN } from './dictionary';
import clinicsEN from './clinics-en.json';
import nightClubEN from './night-club-en.json';
import restaurantEN from './restaurant-en.json';
import coWorkingSpaceEN from './co-working-space-en.json';
import { NAMESPACES } from '../../namespaces';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [NAMESPACES.DICTIONARY]: dictionaryEN,
  [NAMESPACES.RESTAURANT]: { ...dictionaryEN, ...restaurantEN },
  [NAMESPACES.CLINICS]: { ...dictionaryEN, ...clinicsEN },
  [NAMESPACES.NIGHT_CLUB]: { ...dictionaryEN, ...nightClubEN },
  [NAMESPACES.COWORKING_SPACE]: { ...dictionaryEN, ...coWorkingSpaceEN },
};
