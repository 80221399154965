import Main from "./pages/Main";
import Layout from "./layouts/Layout";
import { Routes, Route } from "react-router-dom";

import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';

import "./style/swal.css";
import "./style/time.css";
import "./style/steps.css";
import "./style/final.css";
import "./style/activity.css";
import "./style/calendar.css";
import "./style/user-data.css";
import "./style/navigation.css";
import "./style/progress-bar.css";
import "./style/google-input.css";
import "./style/language-picker.css";
import "./style/step-navigation.css";
import "./style/number-of-guests.css";
import "./style/company-location.css";
import "./style/reservation-details.css";

import CompanyLocations from "./pages/CompanyLocations";
import OnlineReservations from "./pages/OnlineReservations";
import CompanyNotFound from "./pages/CompanyNotFound";
import ReservationProviders from './layouts/ReservationProvider';
import ReservationDetails from "./pages/ReservationDetails";
import ReservationConfirmation from "./pages/ReservationConfirmation";
import useTranslationLocationType from "./hooks/useTranslationLocationType";
import ReservationCancellation from "./pages/ReservationCancellation";

function App() {
  useTranslationLocationType();

  return (
      <Routes>
        <Route path="" element={<Main />} />
        <Route element={<ReservationProviders />}>
          <Route element={<Layout />}>
            <Route path=":companySlug" element={<CompanyLocations />} />
            <Route
              path=":companySlug/:locationSlug"
              element={<OnlineReservations />}
            />
          </Route>
        </Route>
        <Route path="r/:reservationId" element={<ReservationDetails />} />
        <Route path="c/:reservationId" element={<ReservationConfirmation />} />
        <Route path="cancel/:reservationId" element={<ReservationCancellation />} />

        <Route path="404" element={<CompanyNotFound />} />
      </Routes>
  );
}

export default App;
