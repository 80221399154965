import { useTranslation } from "react-i18next";

const CompanyNotFound = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("NotFound.Title")}</h1>
    </div>
  );
};

export default CompanyNotFound;
