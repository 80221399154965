import { useMatch } from "react-router-dom";
import { GetRequest } from "../api/requests/getRequest";
import React, { useEffect, useState } from "react";
import AwatingReservationDetails from "../components/AwatingReservationDetails";
import ClientConfirmationDetails from "../components/ClientConfirmationDetails";

export default function ReservationConfirmation() {
  const [reservation, setReservation] = useState([]);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [status, setStatus] = useState("");

  const match = useMatch("/c/:reservationId");
  const code = match?.params.reservationId;

  useEffect(() => {
    const LoadReservationData = async () => {
      try {
        await GetRequest(`/api/v1/reservations/${code}`).then(
          (response) => {
            setReservation(response);
            setStatus(response.status);
            setStart(new Date(response.start));
            setEnd(new Date(response.end));
          }
        );
      } catch {
        // NAVIGIRAJ NEGDI
      }
    };

    LoadReservationData();
  }, [code]);

  return (
    <>
      { 
        status === "ClientConfirmed" ? 
          <AwatingReservationDetails reservation={reservation} code={code} start={start} end={end} />
          : <ClientConfirmationDetails reservation={reservation} start={start} end={end} />
      }
    </>
  );
}
