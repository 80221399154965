import { useContext } from "react";

import { ReservationNavigationContext } from "../context/reservationNavigationContext";

export default function ProgressBar() {
  const { step, filteredSteps } = useContext(ReservationNavigationContext);

  const stepIndex = filteredSteps.findIndex(({ id }) => id === step?.id);
  const normalizedStepIndex = stepIndex === -1 ? 0 : stepIndex + 1;
  const totalSteps = filteredSteps.length || 1;
  const width = `${(normalizedStepIndex / totalSteps) * 100}%`;

  return (
    <div className="progress-bar-container">
      <div className="progress-bar-step" style={{ width }} />
    </div>
  );
}
