import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { OptionsRequest } from "../api/requests/optionsRequest";
import { useMemo } from "react";
import { LocationTypeContext } from "../context/locationContext";
import { useContext } from "react";

const DEFAULT_DATA = {
  steps: [],
  activities: [],
  units: [],
  locationId: "",
  locationName: "",
  contact: {},
};

export const useReservationData = () => {
  // Statuses: idle - no request, pending - request is made, success - request is successful
  const [isDataFetched, setIsDataFetched] = useState({
    company: false,
    location: false,
  });
  const [locations, setLocations] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const { setLocationType } = useContext(LocationTypeContext);

  const navigate = useNavigate();
  const matchCompany = useMatch("/:companySlug");
  const matchLocation = useMatch("/:companySlug/:locationSlug/*");

  useEffect(() => {
    if (matchCompany && !isDataFetched.company) {
      setIsDataFetched((prev) => ({
        ...prev,
        company: true,
      }));

      OptionsRequest(`/api/v1/locations/${matchCompany?.params.companySlug}`)
        .then((data) => {
          if (!data || data.locations.length === 0) {
            navigate(`/404`, { replace: true });
            return;
          }

          const mappedData = data.locations.reduce((acc, curr) => {
            acc[curr.locationSlug] = curr;
            return acc;
          }, {});

          setCompanyName(data.companyName);
          setLocations(mappedData);
        })
        .catch(() => navigate(`/404`, { replace: true }));
    }

    if (matchLocation && !isDataFetched.location && !isDataFetched.company) {
      setIsDataFetched((prev) => ({
        ...prev,
        location: true,
      }));

      OptionsRequest(
        `/api/v1/locations/${matchLocation.params.companySlug}/${matchLocation.params.locationSlug}`
      )
        .then((data) => {
          if (!data) {
            navigate(matchLocation.params.companySlug, { replace: true });
            return;
          }

          const mappedData = { [data.locationSlug]: data };

          setLocations(mappedData);
          setLocationType(data.type);
        })
        .catch(() => navigate(`/404`, { replace: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchCompany?.params.companySlug]);

  const data = useMemo(
    () => ({
      companyName,
      locations,
      currentLocation:
        locations[matchLocation?.params.locationSlug] || DEFAULT_DATA,
    }),
    [locations, matchLocation?.params.locationSlug, companyName]
  );

  return data;
};
