import { useContext, useEffect } from "react";
import { ReservationDataContext } from "../context/reservationDataContext";
import { ReservationEntryContext } from "../context/reservationEntryContext";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import { useTranslation } from "react-i18next";

export default function Rooms() {
  const { t } = useTranslation();

  const { reservation, onReservationChange } = useContext(ReservationEntryContext);

  const { rooms = [] } = useContext(ReservationDataContext);
  const { nextStep } = useContext(ReservationNavigationContext);

  useEffect(() => {
    onReservationChange({ entries: getNewEntry("") });
  }, [onReservationChange]);

  const handleRoomChange = (e) => {
    var newEntry = reservation.entries;
    var wantedRoom = rooms.filter((x) => x.id === e.target.id);

    if (wantedRoom.length === 0) return;
    newEntry[0].roomId = e.target.id;

    onReservationChange({ entries: newEntry });
    nextStep();
  };

  const getNewEntry = (id) => {
    return [
      {
        roomId: id,
        durationInMinutes: 0,
        units: [],
      },
    ];
  };

  return (
    <div>
      <h1 className="large-p">{t('RoomsStep.Title')}</h1>

      <div className="page" style={{ maxWidth: "30rem", padding: "0 5%" }}>
        <div className="wrapper">
          {rooms.map((room) => {
            return (
              <div key={room.name} className="activity">
                {room.imagePath !== "" ? 
                  <div className="activity-image">
                    <img alt="room" src={room.imagePath} />
                  </div> 
                : null}
                
                <h3 className="activity-title">{room.name}</h3>

                {room.description !== "" && room.description !== undefined ? 
                  <p className="activity-text">
                    {room.description}
                  </p>
                : null}

                <div className="activity-footer">
                  <button
                    id={room.id}
                    className="activity-button"
                    onClick={handleRoomChange}
                  >
                    {t("ActivitiesStep.BookNow")}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
