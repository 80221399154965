import { useMatch, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { LocationsContext } from "../context/reservationDataContext";
import { ReservationEntryContext } from "../context/reservationEntryContext";
import { RESERVATION_MODEL } from "../models/reservation";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import { useTranslation } from "react-i18next";
import { LocationTypeContext } from "../context/locationContext";

const CompanyLocations = () => {
  const companyData = useContext(LocationsContext);
  const { onReservationChange } = useContext(ReservationEntryContext);
  const { resetNavigationData, setTitle } = useContext(
    ReservationNavigationContext
  );
  const { setLocationType } = useContext(LocationTypeContext);

  const match = useMatch("/:companySlug");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLocationChange = (e) => {
    const data = e.target.dataset;
    setTitle(data.title);
    setLocationType(data.type);
    navigate(`/${match.params.companySlug}/${data.location}`);
  };

  useEffect(() => {
    setTitle(companyData.companyName);
    // Reset reservation data when changing location
    onReservationChange(RESERVATION_MODEL);
    resetNavigationData();
  }, [
    onReservationChange,
    resetNavigationData,
    setTitle,
    companyData.companyName,
  ]);

  return (
    <>
      <h1 className="large-p" style={{color: '#173655'}}>
        {t("Locations.Title")}
      </h1>
      <div className="card-list">
        {Object.values(companyData.locations).map((location) => (
          <div key={location.locationId} className="card">
            <h3 className="card-title">{location.locationName}</h3>
            <p className="card-text">
              {location.contact.address} {location.contact.addressNumber},{" "}
              {location.contact.city}
            </p>
            <div className="card-footer">
              <button
                className="card-button"
                data-location={location.locationSlug}
                data-title={location.locationName}
                data-type={location.type}
                onClick={handleLocationChange}
              >
                {t("Locations.BookThisLocation")}
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CompanyLocations;
