import OptionPicker from "../components/OptionPicker";
import { useContext, useCallback, useEffect, useState } from "react";
import { ReservationDataContext } from "../context/reservationDataContext";
import { ReservationEntryContext } from "../context/reservationEntryContext";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import { useTranslation } from "react-i18next";
import { NUMBER_OF_GUESTS_STEP } from "../constants/reservationSteps";

export default function NumberOfGuests() {
  const [select, setSelect] = useState([]);
  const { units, contact, maxNumberOfPersons, steps } = useContext(
    ReservationDataContext
  );
  const notes = steps.find(x => x.id === NUMBER_OF_GUESTS_STEP).notes;
  const { reservation, onReservationChange } = useContext(
    ReservationEntryContext
  );
  const { nextStep } = useContext(ReservationNavigationContext);

  const { t } = useTranslation();

  const handleNumberOfPersonsChange = useCallback(
    (e) => {
      onReservationChange({ numberOfPersons: e.target.value });
      nextStep();
    },
    [onReservationChange, nextStep]
  );

  useEffect(() => {
    const PopulateData = async () => {
      let arrayOfInputs = [];
      let maxNumberOfGuests = maxNumberOfPersons;

      const selectedUnit = reservation.entries[0].units[0];
      const unitMaxNumberOfGuests = units.find(
        (x) => x.id === selectedUnit
      )?.numberOfPersons;

      if (unitMaxNumberOfGuests !== 0 && unitMaxNumberOfGuests != null)
        maxNumberOfGuests = unitMaxNumberOfGuests;

      for (let i = 1; i < maxNumberOfGuests + 1; i++) {
        arrayOfInputs.push(
          <OptionPicker
            name="number-of-persons"
            key={i}
            identity={i}
            text={i}
            handleChange={handleNumberOfPersonsChange}
          />
        );
      }

      setSelect(arrayOfInputs);
    };

    PopulateData();
  }, [
    handleNumberOfPersonsChange,
    reservation.entries,
    units,
    maxNumberOfPersons
  ]);

  return (
    <div className="number-of-guests-container">
      <h1 className="large-p">{t("NumberOfGuestsStep.Title")}</h1>
      
      {notes.length > 0 ? 
        <div style={{margin:'0 0 2em 0', textAlign: 'center', color: '#173655', fontWeight: 500, maxWidth: '20rem'}}>
          {notes.map((note) =>
            <p style={{margin: 0, fontSize: '0.9em'}}>{note}</p>
          )}
        </div>
      : null}
      <div className="page">
        <div className="wrapper">{select}</div>
      </div>
      <div className="align-center-with-margin">
        {t("NumberOfGuestsStep.Text", { count: select.length })}
        <br />
        <a href={"mailto:" + contact.email}> {contact.email}</a>
        
        {/* 
        {" "}{t("NumberOfGuestsStep.OrAt")}{" "}
        <a href={"tel:" + contact.phone}> {contact.phone}.</a>
        */}
      </div>
    </div>
  );
}
